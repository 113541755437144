import React, { useEffect, useState } from "react";
import * as baseProductApi from "../../apis/baseProductApi";
import { fixImagePath, showMessage } from 'Utils/global';
import { useSelector } from "react-redux";
import { Card, Table, Row, Col, Image } from 'antd';
import MetaTags from 'react-meta-tags';
import { PropTypes } from 'prop-types'
import { withRouter, Link } from "react-router-dom";
import ListVariant from "./ListVariant";

ListBaseProduct.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
}

function ListBaseProduct(props) {
  const selectedShop = useSelector(state => state.SelectedShop);
  const [baseProducts, setBaseProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState();
  const [variantModalVisible, setVariantModalVisible] = useState(false);

  useEffect(() => {
    if (selectedShop > 0) {
      fetchBaseProducts(1);
    }
  }, [selectedShop]);

  const fetchBaseProducts = (page, pageSize) => {
    const params = [];
    params.push({ name: 'page', value: page });
    params.push({ name: 'per_page', value: pageSize });
    params.push({ name: 'company_id', value: selectedShop });
    baseProductApi.getList(params, onSuccess, showMessage);
  }

  const onSuccess = (res) => {
    let data = res.data.data.data;
    let fromIndex = res.data.data.from;
    let lbp = data.map((item, index) => { 
      return { ...item, 
        key: item.product_id, 
        stt: fromIndex + index } 
    })
    setBaseProducts(lbp);
    setTotalPages(res.data.data.total);
  }

  const onCloseVariantModal = () => {
    setVariantModalVisible(false);
  }

  const onShowVariantModal = (baseProduct) => {
    setSelectedProduct(baseProduct);
    setVariantModalVisible(true);
  }

  const baseProductColumns = [
    {
      title: 'Sản phẩm cơ bản',
      dataIndex: 'code_anh',
      align: 'left',
      hidden: false,
      responsive: ["xs"],
      render: (text, record) => <Col>
        <Row>
          <Col span={7}>Mã SP: </Col>
          <Col>{record.product_code}</Col>
        </Row>

        <Row>
          <Col span={7}>Tên SP: </Col>
          <Col>{record.product_web_name}</Col>
        </Row>

        <Row>
          <Col span={7}>ĐVT: </Col>
          <Col>{record.unit_code}</Col>
        </Row>

        <Row>
          <Col span={7}>Nhóm SP: </Col>
          <Col>{record.web_category?.category_name}</Col>
        </Row>

        <Row>
          <Col span={7}>Thương hiệu: </Col>
          <Col>{record.brand?.brand_name}</Col>
        </Row>

        <Row>
          <Link className='text-primary' to='#' onClick={() => onShowVariantModal(record)} >
            <i className="fas fa-list"></i>
          </Link>
          <Link className='text-info' to={"/base-products/view/" + record.id} >
            <i className="fas fa-eye"></i>
          </Link>
          <Link className='text-success' to={"/base-products/" + record.id} >
            <i className="fas fa-pen"></i>
          </Link>
        </Row>
      </Col>,
    },
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      width: 75,
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Ảnh',
      dataIndex: 'thumb_file',
      key: 'thumb_file',
      align: 'center',
      width: '10%',
      hidden: false,
      responsive: ["md"],
      render: (thumb_file, record) => {
        const thumbFile = fixImagePath(record.thumb_file);
        const previewFile = fixImagePath(record.slide_file);
        return (
          <Image
            width={64}
            src={thumbFile}
            preview={{ src: previewFile }}
          />
        );
      }
    },
    {
      title: 'Mã SP',
      dataIndex: 'product_code',
      key: 'product_code',
      align: 'center',
      width: 150,
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Tên SP',
      dataIndex: 'product_web_name',
      key: 'product_web_name',
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'ĐVT',
      dataIndex: 'unit_code',
      key: 'unit_code',
      align: 'center',
      width: 80,
      hidden: false,
      responsive: ["md"],
    },
    {
      title: 'Nhóm SP',
      dataIndex: 'category_name',
      key: 'category_name',
      align: 'center',
      width: 150,
      hidden: false,
      responsive: ["md"],
      render: (el, record) => record.web_category?.category_name,
    },
    {
      title: 'Thương hiệu',
      dataIndex: 'brand_name',
      key: 'brand_name',
      align: 'center',
      width: 160,
      hidden: false,
      responsive: ["md"],
      render: (el, record) => record.brand?.brand_name,
    },
    {
      title: '',
      align: 'center',
      width: 120,
      hidden: false,
      responsive: ["md"],
      render: (key, record) => {
        return (
          <>
            <Link className='text-primary mr-3' to='#' onClick={() => onShowVariantModal(record)} >
              <i className="fas fa-list" title="Xem biến thể sản phẩm"></i>
            </Link>
            <Link className='text-info mr-3' to={"/base-products/view/" + record.product_id} >
              <i className="fas fa-eye" title="Xem sản phẩm"></i>
            </Link>
            <Link className='text-success' to={"/base-products/" + record.product_id} >
              <i className="fas fa-pen" title="Sửa sản phẩm"></i>
            </Link>
          </>
        );
      },
    },
  ].filter(e => e.hidden == false);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Danh sách sản phẩm cơ sở</title>
      </MetaTags>
      <Card title='DANH SÁCH SẢN PHẨM CƠ SỞ'>
        <Table className="mytable"
          size="small"
          dataSource={baseProducts}
          columns={baseProductColumns}
          pagination={{
            defaultPageSize: 10,
            total: totalPages,
            locale: { items_per_page: "sản phẩm" },
            onChange: (page, items_per_page) => {
              fetchBaseProducts(page, items_per_page);
            },
          }} />
      </Card>
      <ListVariant
        visible={variantModalVisible}
        baseProduct={selectedProduct}
        onClose={onCloseVariantModal} />
    </div>
  );
}

export default withRouter(ListBaseProduct);
