import React from "react"
import { Row, Col, Tag } from "antd"
import baseColumnProps from 'components/Common/Table/Column/BaseColumnProps'
import getColumnSearchProps from 'components/Common/Table/Filter/FullLoadedTableFilter'

export const getFindSaleProductColumns = (handleDelete, handleRestore) => [
  {
    title: "Sản phẩm sale",
    dataIndex: "id",
    align: "left",
    hidden: false,
    responsive: ["xs"],
    render: (text, record) => (
      <Col>
        <Row>
          <Col span={7}>Mã SP: </Col>
          <Col>{record.product_code}</Col>
        </Row>
        <Row>
          <Col span={7}>Code ảnh: </Col>
          <Col>{record.code_anh}</Col>
        </Row>
        <Row>
          <Col span={7}>Tên SP: </Col>
          <Col>{record.product_name}</Col>
        </Row>
        <Row>
          <Col span={7}>Tên chiến dịch sale: </Col>
          <Col>{record.name}</Col>
        </Row>
        <Row>
          <Col span={7}>Từ ngày: </Col>
          <Col>{record.from_date}</Col>
        </Row>
        <Row>
          <Col span={7}>Đến ngày: </Col>
          <Col>{record.to_date}</Col>
        </Row>
        <Row>
          <Col span={7}>Giá gốc: </Col>
          <Col>{record.original_price}</Col>
        </Row>
        <Row>
          <Col span={7}>Giá bình thường: </Col>
          <Col>{record.product_price}</Col>
        </Row>
        <Row>
          <Col span={7}>Giá sale bình thường: </Col>
          <Col>{record.product_sale_price}</Col>
        </Row>
        <Row>
          <Col span={7}>Giá gốc hiển thị trong chiến dịch sale: </Col>
          <Col>{record.campaign_retail_price}</Col>
        </Row>
        <Row>
          <Col span={7}>Giá sale trong chiến dịch: </Col>
          <Col>{record.campaign_sale_price}</Col>
        </Row>
        <Row>
          <Col span={7}>Tình trạng: </Col>
          <Col>{record.status_text}</Col>
        </Row>
      </Col>
    ),
  },
  {
    ...baseColumnProps('STT', 'stt'),
    width: 60,
  },
  {
    ...baseColumnProps('Mã SP', 'product_code'),
    width: 110,
  },
  {
    ...baseColumnProps('Code ảnh', 'code_anh'),
    width: 160,
  },
  {
    ...baseColumnProps('Tên SP', 'product_name'),
    width: 'auto',
    render: (text, record) => (text.length > 30 ? text.slice(0, 30) + '...' : text),
  },
  {
    ...baseColumnProps('Tên chiến dịch', 'name'),
    width: 'auto',
  },
  {
    ...baseColumnProps('Từ ngày', 'from_date'),
    width: 100,
  },
  {
    ...baseColumnProps('Đến ngày', 'to_date'),
    width: 100,
  },
  {
    ...baseColumnProps('Giá gốc', 'original_price'),
    width: 90,
  },
  {
    ...baseColumnProps('Giá bình thường', 'product_price'),
    width: 90,
  },
  {
    ...baseColumnProps('Giá sale', 'product_sale_price'),
    width: 90,
  },
  {
    ...baseColumnProps('Giá chiến dịch', 'campaign_retail_price'),
    width: 90,
  },
  {
    ...baseColumnProps('Giá sale chiến dịch', 'campaign_sale_price'),
    width: 90,
  },
  {
    ...baseColumnProps('Trạng thái', 'status_text'),
    width: 90,
    ...getColumnSearchProps('status_text', 'trạng thái'),
    render: (text, record) => (<Tag color={getStatusColor(record.status)}>{text}</Tag>),
  },
].filter(e => e.hidden == false);

const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return "green";
    case 2:
      return "gold";
    case 3:
      return "cyan";
    default:
      return "red";
  }
}