import React, { Component } from "react";
import PropTypes from "prop-types";

import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Import config
import { facebook, google } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.apiError("");
  }

  signIn = (res, type) => {
    const { socialLogin } = this.props;
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        username: res.profileObj.username,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        username: res.username,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, this.props.history, type);
    }
  };

  toggleShowPasswordInput(id) {
    if (document.getElementById(id).type == 'password') {
      document.getElementById(id).type = 'text';
      document.getElementById("password-ico").className = "mdi mdi-eye-off-outline";
    }
    else {
      document.getElementById(id).type = 'password';
      document.getElementById("password-ico").className = "mdi mdi-eye-outline";
    }
  }

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response, "google");
  };

  //handleTwitterLoginResponse
  twitterResponse = () => {};

  //handleFacebookLoginResponse
  facebookResponse = response => {
    this.signIn(response, "facebook");
  };

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Hệ thống Quản lý kho Season !</h5>
                          <p>Đăng nhập để tiếp tục.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">                    
                    <div className="p-2">
                    {
                      this.props.error && this.props.error ? (
                      <Alert color="danger">{this.props.error}</Alert>
                    ) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          username:
                            (this.state && this.state.username) || "",
                          password:
                            (this.state && this.state.password) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().required(
                            "Nhập tên đăng nhập"
                          ),
                          password: Yup.string().required(
                            "Nhập mật khẩu"
                          ),
                        })}
                        onSubmit={values => {
                          this.props.loginUser(values, this.props.history);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="username" className="form-label">
                                Tên đăng nhập
                              </Label>
                              <Field
                                name="username"
                                type="text"
                                className={
                                  "form-control" 
                                  // +
                                  // (errors.username && touched.username
                                  //   ? " is-invalid"
                                  //   : "")
                                }
                              />
                              <ErrorMessage
                                name="username"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Mật khẩu
                              </Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  id="password"
                                  name="password"
                                  type="password"
                                  autoComplete="true"
                                  className={
                                    "form-control" 
                                    // +
                                    // (errors.password && touched.password
                                    //   ? " is-invalid"
                                    //   : "")
                                  }
                                />
                                <button
                                  className="btn btn-light "
                                  type="button"
                                  id="password-addon"
                                  onClick={e => this.toggleShowPasswordInput('password')}
                                >
                                  <i id="password-ico" className="mdi mdi-eye-outline"></i>
                                </button>
                              </div>
                              <ErrorMessage
                              name="password"
                              component="div"
                              className="invalid-feedback"
                            />
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>


                            <div className="mt-4 text-center">
                              <i className="mdi mdi-lock me-1" /> Quên mật khẩu? Liên hệ với admin!                              
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} Season. Thực hiện với
                    <i className="mdi mdi-heart text-danger" /> bởi JupiterMedia
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);
