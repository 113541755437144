import React, { useEffect, useState, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import * as saleCampaignApi from "../../apis/saleCampaignApi";
import { convertNumberToCurrency, formatMoney, generateCampaign, showMessage } from 'Utils/global';
import { useHistory, withRouter } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { MetaTags } from 'react-meta-tags';
import SaleProducts from './SaleProducts';
import { useSelector } from 'react-redux';
import { Descriptions } from 'antd';

export const SaleProductContext = createContext();

const reducerSaleProducts = (currentSaleProducts, action) => {
  switch (action.type) {
    case 'POPULATE':
      let products = action.payload
      products.forEach(function (row, index) {
        row.key = row.id;
        row.stt = index + 1;

        row.original_price = parseInt(row.original_price);
        row.display_original_price = convertNumberToCurrency(row.original_price);

        row.price = parseInt(row.price);
        row.display_price = convertNumberToCurrency(row.price);

        row.retail_price = parseInt(row.retail_price);
        row.display_retail_price = convertNumberToCurrency(row.retail_price);

        row.sale_price = parseInt(row.sale_price);
        row.display_sale_price = convertNumberToCurrency(row.sale_price);
      });
      return products;
    case 'UPDATE':
      const updatedSaleProducts = currentSaleProducts.map((el) => {
        if (el.product_id == action.payload.product_id) {
          return {
            ...el, ...{
              sale_price: parseInt(action.payload.sale_price),
              display_sale_price: convertNumberToCurrency(parseInt(action.payload.sale_price))
            }
          }
        }
        return el;
      })
      return updatedSaleProducts;
    case 'DELETE':
      const notDeletedProducts = currentSaleProducts.filter(el => el.id != action.payload.id);
      notDeletedProducts.forEach(function (row, index) {
        row.stt = index + 1;
      });
      return notDeletedProducts;
    default:
      return currentSaleProducts;
  }
}

const initialSaleCampain = {
  id: 0,
  name: '',
  from_date: '',
  to_date: '',
  total_product_count: 0,
  total_product_sold: 0,
  total_revenue_amount: 0,
  total_profit_amount: 0,
  from_date_part: '',
  from_time_part: '00:00:00',
  to_date_part: '',
  to_time_part: '23:59:59',
}

SaleCampaign.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

function SaleCampaign(props) {
  const id = props.match.params?.id;
  let isView = props.match.url.includes("sale-campaigns/view")
  let history = useHistory();
  const selectedShop = useSelector(state => state.SelectedShop)
  const [saleProducts, dispatchSaleProducts] = useReducer(reducerSaleProducts, []);

  const [saleCampaign, setSaleCampaign] = useState(initialSaleCampain);

  const [productTableVisible, setProductTableVisible] = useState(true);

  useEffect(() => {
    if (id > 0) saleCampaignApi.get(id, getSaleCampaignSuccess, showMessage);
  }, [id])

  const getSaleCampaignSuccess = (res) => {
    let saleCampaignData = res.data.data;
    let products = saleCampaignData.products;
    dispatchSaleProducts({ type: 'POPULATE', payload: products });
    let campaign = {
      id: saleCampaignData.id,
      name: saleCampaignData.name,
      from_date: saleCampaignData.from_date,
      to_date: saleCampaignData.to_date,
      total_product_count: saleCampaignData.total_product_count,
      total_product_sold: saleCampaignData.total_product_sold,
      total_revenue_amount: saleCampaignData.total_revenue_amount,
      total_profit_amount: saleCampaignData.total_profit_amount,
    };
    let newCampaign = generateCampaign(campaign)
    setSaleCampaign({ ...saleCampaign, ...newCampaign });
    setProductTableVisible(false);
  }

  const onInputChange = (key, value) => {
    setSaleCampaign({ ...saleCampaign, ...{ [key]: value } });
  }

  const saveSaleCampaign = () => {
    if (saleCampaign.id) {
      const updateData = {
        name: saleCampaign.name,
        from_date: [saleCampaign.from_date_part, saleCampaign.from_time_part].join(' '),
        to_date: [saleCampaign.to_date_part, saleCampaign.to_time_part].join(' '),
      }
      saleCampaignApi.update(saleCampaign.id, updateData, onUpdateSuccess, showMessage);
    } else {
      const creationData = {
        name: saleCampaign.name,
        from_date: [saleCampaign.from_date_part, saleCampaign.from_time_part].join(' '),
        to_date: [saleCampaign.to_date_part, saleCampaign.to_time_part].join(' '),
        company_id: selectedShop,
        details: []
      }
      saleCampaignApi.create(creationData, onCreateSuccess, showMessage);
    }
  }

  const onUpdateSuccess = res => {
    message.success(res.data.message);
  };

  const onCreateSuccess = res => {
    // setSaleCampaign({...saleCampaign, ...res.data.data});
    // setProductTableVisible(false);
    history.push("/sale-campaigns/" + res.data.data.id)
    message.success(res.data.message);
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Chi tiết khuyến mại</title>
      </MetaTags>
      <Card>
        <CardHeader className="h3 bg-transparent border-bottom">
          CHIẾN DỊCH KHUYẾN MẠI
        </CardHeader>
        <CardBody className='pt-1 pb-1'>
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <CardHeader style={{backgroundColor: "#EFF2F7"}}>
                  Thông tin cơ bản
                </CardHeader>
                <CardBody className='pt-1 pb-1 mt-3'>
                  <Form>
                    <FormGroup row={true}>
                      <Label xs={12} sm={2}>Tên</Label>
                      <Col xs={12} sm={10}>
                        <Input
                          type='text'
                          value={saleCampaign.name}

                          onChange={e => onInputChange('name', e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row={true}>
                      <Label xs={12} sm={2}>Từ ngày</Label>
                      <Col xs={12} sm={10}>
                        <Row>
                          <Col xs={6} sm={2} style={{ minWidth: '170px' }}>
                            <Input
                              type='date'
                              value={saleCampaign.from_date_part}
                              onChange={e => onInputChange('from_date_part', e.target.value)}
                            />
                          </Col>
                          <Col xs={6} sm={2} style={{ minWidth: '120px' }}>
                            <Input
                              type='time'
                              value={saleCampaign.from_time_part}
                              onChange={e => onInputChange('from_time_part', e.target.value)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                    <FormGroup row={true}>
                      <Label xs={12} sm={2}>Đến ngày</Label>
                      <Col xs={12} sm={10}>
                        <Row>
                          <Col xs={6} sm={2} style={{ minWidth: '170px' }}>
                            <Input
                              type='date'
                              value={saleCampaign.to_date_part}
                              onChange={e => onInputChange('to_date_part', e.target.value)}
                            />
                          </Col>
                          <Col xs={6} sm={2} style={{ minWidth: '120px' }}>
                            <Input
                              type='time'
                              value={saleCampaign.to_time_part}
                              onChange={e => onInputChange('to_time_part', e.target.value)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <CardHeader style={{backgroundColor: "#EFF2F7"}}>
                  Thống kê
                </CardHeader>
                <CardBody className='pt-1 pb-3 mt-3'>
                  <Descriptions bordered column={1} size='small'>
                    <Descriptions.Item label="Tổng SL SP" labelStyle={{ width: "150px" }}>{saleCampaign.total_product_count}</Descriptions.Item>
                    <Descriptions.Item label="Tổng SL đã bán">{saleCampaign.total_product_sold}</Descriptions.Item>
                    <Descriptions.Item label="Doanh thu">{formatMoney(saleCampaign.total_revenue_amount)}</Descriptions.Item>
                    <Descriptions.Item label="Lợi nhuận">{formatMoney(saleCampaign.total_profit_amount)}</Descriptions.Item>
                  </Descriptions>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="h5 bg-transparent border-top">
          <Button color="secondary" className='mr-2' onClick={() => history.goBack()}>
            Quay lại
          </Button>
          {isView ? null :
            <Button color="primary" onClick={saveSaleCampaign}>
              Lưu lại
            </Button>}
        </CardFooter>
      </Card>
      <SaleProductContext.Provider value={{
        products: saleProducts, productsDispatch: dispatchSaleProducts,
        companyId: selectedShop,
      }}>
        <SaleProducts campaignId={saleCampaign.id} isView={isView} productTableVisible={productTableVisible} />
      </SaleProductContext.Provider>
    </div>
  );
}

export default withRouter(SaleCampaign);