import React, { useState, useEffect } from "react"
import { Row, Col, Label } from "reactstrap"
import Select from "react-select"
import { PropTypes } from 'prop-types';

import * as roleApi from './../../../apis/roleApi';

FilterSalemans.propTypes = {
    onChange: PropTypes.func,
}

function FilterSalemans({ onChange }) {
    const [userIds, setUserIds] = useState();
    const [users, setUSers] = useState();

    const handleChange = (selectedUsers) => {
        let listUser = []
        selectedUsers?.map(item => {
            listUser.push(item.value)
        })
        onChange(listUser.join())
    }

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        roleApi.getListUser(parseData, showErr)
    }

    const parseData = (res) => {
        let listUser = res.data.data.map(item => {
            return {
                label: item.fullname,
                value: item.user_id
            }
        })
        setUSers(listUser);
    }

    const showErr = (res) => {
        alert(res.message);
    }

    return (
        <Row xl={12} className="mb-2">
            <Label md={12} xl={2} className="col-form-label">NV bán hàng</Label>
            <Col md={12} xl={4}>
                <Select
                    value={userIds}
                    isMulti={true}
                    onChange={handleChange}
                    options={users}
                    classNamePrefix="select2-selection"
                />
            </Col>
        </Row>
    );
}

export default FilterSalemans;