import React, { useContext, useEffect, useRef, useState } from 'react';
import { Image, message, Popconfirm, Table } from 'antd';
import PropTypes from 'prop-types';
import getColumnSearchProps from 'components/Common/TableFilter';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { SaleProductContext } from './SaleCampaign';
import { fixImagePath, showMessage } from 'Utils/global';
import * as saleCampaignDetailApi from "../../apis/saleCampaignDetailApi";
import EditSaleProductModal from './EditSaleProductModal';
import { nullSaleProduct } from 'UsePages/Product/const';
import AddSaleProductsModal from './AddSaleProductsModal';
import { isRole } from 'Utils/SecurityUtils';
import { CSVLink } from 'react-csv';

SaleProducts.propTypes = {
  campaignId: PropTypes.number,
  isView: PropTypes.bool,
  saleProducts: PropTypes.array,
  productTableVisible: PropTypes.bool,
};

function SaleProducts({ campaignId, isView, productTableVisible }) {
  const saleProductContext = useContext(SaleProductContext)
  const { products, productsDispatch } = saleProductContext

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [saleProductModalVisible, setSaleProductModalVisible] = useState(false);
  const [editSaleProductModalVisible, setEditSaleProductModalVisible] = useState(false);
  const [editingSaleProduct, setEditingSaleProduct] = useState(nullSaleProduct);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    console.log('products', products);
    updateFilteredProducts(products);
}, [products])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const updateFilteredProducts = (filteredSource) => {
    const filteredData = filteredSource.map((currentValue, index) => {
      return {
        "STT": currentValue.stt,
        "Mã SP": currentValue.product_code,
        "Tên SP": currentValue.product_name,
        "Giá gốc": parseInt(currentValue.display_original_price.replaceAll(',', '')),
        "Giá bán": parseInt(currentValue.display_price.replaceAll(',', '')),
        "Giá retail": parseInt(currentValue.display_retail_price.replaceAll(',', '')),
        "Giá km": parseInt(currentValue.display_sale_price.replaceAll(',', '')),
        "Trạng thái": currentValue.status_text,
      }
    });
    setFilteredProducts(filteredData);
  }

  const onChange = (pagination, filters, sorter, extra) => {
    extra.currentDataSource.map((currentValue, index) => {
      currentValue.stt = index + 1;
    });
    updateFilteredProducts(extra.currentDataSource);
  };

  const onDelete = detailId => {
    saleCampaignDetailApi.destroy(campaignId, detailId,
      (res) => {
        message.success(res.data.message);
        productsDispatch({ type: 'DELETE', payload: { id: detailId } });
      },
      showMessage);
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: '3%',
      align: 'center',
      hidden: false,
    },
    {
      title: 'Ảnh',
      dataIndex: 'thumb_file',
      key: 'thumb_file',
      width: '7%',
      hidden: false,
      render: (thumb_file, record) => {
        const thumbFile = fixImagePath(record.thumb_file);
        const previewFile = fixImagePath(record.slide_file);
        return (
          <Image
            width={64}
            src={thumbFile}
            preview={{ src: previewFile }}
          />
        );
      }
    },
    {
      title: 'Code ảnh',
      dataIndex: 'code_anh',
      key: 'code_anh',
      width: '14%',
      hidden: !isRole('Content'),
      ...getColumnSearchProps('code_anh', 'code ảnh'),
    },
    {
      title: 'Mã SP',
      dataIndex: 'product_code',
      key: 'product_code',
      width: '10%',
      hidden: false,
      ...getColumnSearchProps('product_code', 'mã sản phẩm'),
    },
    {
      title: 'Tên SP',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '28%',
      hidden: false,
      ...getColumnSearchProps('product_name', 'tên sản phẩm'),
    },
    {
      title: 'Tên web',
      dataIndex: 'product_web_name',
      key: 'product_web_name',
      width: '28%',
      hidden: !isRole('Content'),
      ...getColumnSearchProps('product_name', 'tên web'),
    },
    {
      title: 'Content',
      dataIndex: 'product_content',
      key: 'product_content',
      width: '28%',
      hidden: !isRole('Content'),
      render: (key, record) => {
        return (<Row>
          <a className='text-alert' onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(record.product_content);
            message.info('Đã copy content sản phẩm');
          }}>
            <i className="fas fa-copy"></i>
          </a>
        </Row>);
      },
    },
    {
      title: 'Giá gốc',
      dataIndex: 'display_original_price',
      key: 'display_original_price',
      width: '10%',
      align: 'right',
      hidden: isRole('Content'),
      ...getColumnSearchProps('original_price', 'giá gốc'),
    },
    {
      title: 'Giá bán',
      dataIndex: 'display_price',
      key: 'display_price',
      width: '10%',
      align: 'right',
      hidden: isRole('Content'),
      ...getColumnSearchProps('price', 'giá bán'),
    },
    {
      title: 'Giá retail',
      dataIndex: 'display_retail_price',
      key: 'display_retail_price',
      width: '10%',
      align: 'right',
      hidden: false,
      ...getColumnSearchProps('retail_price', 'giá retail'),
    },
    {
      title: 'Giá km',
      dataIndex: 'display_sale_price',
      key: 'display_sale_price',
      width: '10%',
      align: 'right',
      hidden: false,
      ...getColumnSearchProps('sale_price', 'giá khuyến mại'),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status_text',
      key: 'status_text',
      width: '8%',
      align: 'center',
      hidden: false,
      ...getColumnSearchProps('status_text', 'trạng thái sản phẩm'),
    },
    {
      title: 'Hành động',
      width: '8%',
      align: 'center',
      hidden: false,
      render: (key, record) => {
        return (isView ? null : <Row>
          <Col xs="auto" sm="auto">
            <a className='text-alert' onClick={() => {
              setEditSaleProductModalVisible(true);
              setEditingSaleProduct(record);
            }}>
              <i className="fas fa-pen"></i>
            </a>
          </Col>
          <Col xs="auto" sm="auto">
            <Popconfirm
              placement="top"
              title={"Bạn có muốn xóa chiến dịch khuyến mại?"}
              onConfirm={() => onDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <a className="text-danger"><i className="fas fa-trash"></i></a>
            </Popconfirm>
          </Col>
        </Row>);
      },
    },
  ].filter(e => e.hidden == false);

  if (productTableVisible) return null
  return (
    <Card>
      <CardHeader className="h4 bg-transparent border-bottom">
        DANH SÁCH SẢN PHẨM KHUYẾN MẠI
      </CardHeader>
      <CardBody className='pt-1 pb-1'>
        {!isView && <Button
          color="primary"
          className="px-3 mr-2 mb-1 mt-1"
          onClick={() => setSaleProductModalVisible(true)}>
          <i className="fas fa-plus mr-2"></i>Thêm sản phẩm
        </Button>}
        <Button
          color="primary"
          className="px-3 mr-2 mb-1 mt-1"
          >
          <CSVLink style={{color: "white"}} data={filteredProducts} filename={"san-pham-sale.csv"}>
            Download
          </CSVLink>
        </Button>
        <Table
          className='mytable'
          size='small'
          pagination={{
            hideOnSinglePage: true,
          }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={products}
          onChange={onChange}
        />
        <AddSaleProductsModal campaignId={campaignId}
          visible={saleProductModalVisible}
          onClose={() => setSaleProductModalVisible(false)}
        />
        <EditSaleProductModal campaignId={campaignId}
          saleProduct={editingSaleProduct}
          visible={editSaleProductModalVisible}
          onClose={() => {
            setEditSaleProductModalVisible(false);
            setEditingSaleProduct(nullSaleProduct);
          }}
        />
      </CardBody>
    </Card>
  );
}

export default SaleProducts;