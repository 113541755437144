import React, { useCallback, useEffect, useReducer, useState } from "react"
import { PropTypes } from "prop-types"
import { Button, Form, Space, Card, Select, message, AutoComplete, Spin, Input } from 'antd';
import { useHistory, withRouter } from "react-router-dom";
import * as authApi from "apis/authApi";
import * as wareHouseApi from "apis/wareHouseApi";
import Alert from "components/Common/Alert/Alert";
import { layout, tailLayout, validateMessages } from "components/Common/Form/CommonProps";
import { formStateAction, formStateReducer } from "components/Common/Form/FormStateReducer";
import { debounce } from "Utils/global";
import { MetaTags } from "react-meta-tags";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { useSelector } from "react-redux";


const initialSelectedUser = {
  user_id: 0,
  username: "",
  fullname: "",
};

const UserWarehouse = (props) => {
  const [formState, dispatchFormState] = useReducer(formStateReducer, {
    saving: false,
    loading: false,
    hasError: false,
    message: ''
  });

  const selectedShop = useSelector(state => state.SelectedShop);
  const history = useHistory();
  const [form] = Form.useForm();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (selectedShop && parseInt(selectedShop) > 0) {
      fetchWarehouses();
      if (form.user_id > 0) {
        fetchUserWarehouses(form.user_id);
      } else {
        form.resetFields();
        setSelectedUser(initialSelectedUser);
      }
    }
  }, [selectedShop])

  const fetchWarehouses = () => {
    setLoading(true);
    wareHouseApi.getAll(selectedShop, fetchWarehousesSuccess, fetchWarehousesFailed);
  }

  const fetchWarehousesSuccess = (res) => {
    setLoading(false);
    const items = res.data.data.map(item => {
      return { label: item.warehouse_code, value: item.warehouse_code };
    })
    setWarehouses(items);
  }

  const fetchWarehousesFailed = (err) => {
    setLoading(false);
    message.error(err.message);
  }

  const handleSave = (data) => {
    dispatchFormState({ type: formStateAction.SAVING })
    data.company_id = selectedShop;
    authApi.saveUserWarehouses(data, saveSuccess, saveFailed);
  }

  const saveSuccess = (res) => {
    dispatchFormState({ type: formStateAction.LOAD_SUCCESS, payload: res.data.message });
  }

  const saveFailed = (err) => {
    dispatchFormState({ type: formStateAction.LOAD_FAILED, payload: err.message });
  }

  const handleFinish = (values) => {
    handleSave(values);
  };

  const handleFinishFailed = (values, errorFields, outOfDate) => {
    console.log('handleFinishFailed values = ', values);
    console.log('handleFinishFailed errorFields = ', errorFields);
    console.log('handleFinishFailed outOfDate = ', outOfDate);
  };

  const fetchUserWarehouses = (user_id) => {
    authApi.getWarehouses(user_id, fetchUserWarehousesSuccess, fetchUserWarehousesFailed);
  }

  const fetchUserWarehousesSuccess = (res) => {
    console.log('res.data.data = ', res.data.data);
    console.log('res.data.data[selectedShop] = ', res.data.data[selectedShop]);
    const item = res.data.data[selectedShop]
      ? { 'warehouse_codes': res.data.data[selectedShop] }
      : { 'warehouse_codes': [] };
    form.setFieldsValue(item);
  }

  const fetchUserWarehousesFailed = (err) => {
    message.error(err.message);
  }

  const onSelectUsername = (key, value) => {
    form.setFieldsValue({
      user_id: value.user_id,
      username: value.username,
      fullname: value.fullname,
    })
    fetchUserWarehouses(value.user_id)
  };

  const onChangeUsername = (value) => {
    setSelectedUser(prevState => ({
      ...prevState,
      username: value
    }));
  };

  const onSearchUsername = (value) => {
    setSelectedUser(prevState => ({
      ...prevState,
      username: value
    }));
    fetchUsers(value);
  };

  const fetchUsers = useCallback(
    debounce((username) => {
      if (username) {
        authApi.getListUser([{ name: 'username', value: username }], fetchUsersSuccess, fetchUsersFailed);
      }
    }, 1000),
    []
  );

  const fetchUsersSuccess = (res) => {
    setFilteredUsers(res.data.data.data);
  }

  const fetchUsersFailed = (err) => {
    message.error(err.message);
  }

  const [selectedUser, setSelectedUser] = useState(initialSelectedUser);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Phân quyền kho | Season CMS</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="24 Cara" breadcrumbItem="Phân quyền kho" />
        <Card loading={loading}>
          <Form
            {...layout}
            form={form}
            name="user-warehouse-form"
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            validateMessages={validateMessages}
            autoComplete="off"
          >
            <Form.Item
              label="Tìm kiếm"
              rules={[{ required: true }]} >
              <AutoComplete
                placeholder="Nhập username để tìm kiếm"
                showSearch={true}
                onSelect={onSelectUsername}
                onChange={onChangeUsername}
                onSearch={onSearchUsername}
                filterOption={false}
                value={selectedUser.username}
              >
                {filteredUsers && (filteredUsers instanceof Array) && filteredUsers.map(item => (
                  <Select.Option
                    key={item.user_id}
                    user_id={item.user_id}
                    fullname={item.fullname}
                    username={item.username}
                    value={item.username}
                  >
                    {item.phone} - {item.fullname}
                  </Select.Option>
                ))}
              </AutoComplete>
            </Form.Item>

            <Form.Item
              name="user_id"
              label="ID"
              hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item
              name="username"
              label="Username" >
              <Input readOnly={true} disabled={true} />
            </Form.Item>

            <Form.Item
              name="fullname"
              label="Tên" >
              <Input readOnly={true} disabled={true} />
            </Form.Item>

            <Form.Item
              name="warehouse_codes"
              label="Kho"
            >
              <Select
                placeholder="Chọn kho"
                allowClear={true}
                mode="multiple"
                options={warehouses} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Space direction="vertical">
                <Space>
                  <Button type="primary" htmlType="submit" loading={formState.saving}>
                    Lưu
                  </Button>
                  <Button htmlType="button" onClick={() => form.resetFields()}>
                    Reset
                  </Button>
                  <Button htmlType="button" onClick={() => history.goBack()}>
                    Quay lại
                  </Button>
                </Space>
                <Alert message={formState.message} isError={formState.hasError} />
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

UserWarehouse.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
}

export default withRouter(UserWarehouse)